import { Language, LanguageService } from './../../services/language-service';
import { HttpClient } from 'aurelia-fetch-client';
import { bindable } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { CustomerDocumentType } from 'models';
import { InvoiceWasteByOptions } from 'models/InvoiceWasteBy';
import { Models } from 'models/core';
import { AttachmentHandlerFactory, CustomerDocumentsHandler } from 'services/attachments';
import { Country, CountryService } from 'services/country-service';
import { IndustryService } from 'services/industry-service';

@autoinject()
export class CustomerFormBasic {
  @bindable
  private customer: Models.Customer;
  customerChanged(customer: Models.Customer) {
    if (!customer?.Id) return;

    this.documentHandler = this.attachmentHandlerFactory.customerAttachmentHandler({
      customerId: customer.Id,
      documentType: CustomerDocumentType.ServiceInformation,
    });
  }
  private brregApiUrl: string = 'https://data.brreg.no/enhetsregisteret/api/enheter/';
  private httpClient = new HttpClient();

  protected countries: readonly Country[];
  protected languages: readonly Language[];
  protected industries: Models.Industry[];
  protected documentAttachments = [];

  protected invoiceWasteByOptions = InvoiceWasteByOptions.map((x) => {
    x.label = this.i18n.tr(x.label);
    return x;
  });

  protected documentHandler: CustomerDocumentsHandler;

  constructor(
    private countryService: CountryService,
    private languageService: LanguageService,
    private industryService: IndustryService,
    private i18n: I18N,
    private attachmentHandlerFactory: AttachmentHandlerFactory
  ) {}

  async attached() {
    this.languages = this.languageService.getLanguages();
    this.countries = this.countryService.getCountryList();
    this.industries = await this.industryService.getAll();

    if (this.customer) {
      // most customers will be norwegian, so suggest that
      this.customer.Country = 'Norway';
    }

    if (!this.customer.IndustryId) {
      this.customer.IndustryId = 1;
    }
  }

  protected async fillByOrgNo() {
    if (!this.customer.OrgNo) {
      return;
    }
    this.customer.OrgNo = this.customer.OrgNo.replaceAll(' ', '').trim();
    const response = await this.httpClient.fetch(this.brregApiUrl + this.customer.OrgNo);
    const data = (await response.json()) as {
      navn: string;
      forretningsadresse: { adresse: string[]; postnummer: string; poststed: string; navn: string };
    };
    this.customer.Name = this.companyCase(data.navn);
    this.customer.Address1 = data.forretningsadresse.adresse?.[0];
    this.customer.Address2 = data.forretningsadresse.adresse?.[1];
    this.customer.Zip = data.forretningsadresse.postnummer;
    this.customer.City = data.forretningsadresse.poststed;
  }

  private companyCase(value?: string) {
    if (!value) {
      return;
    }
    value = value.toLowerCase();
    const words = value.split(' ');
    for (let i = 0; i < words.length; i++) {
      let word = words[i];
      word = word[0].toUpperCase() + word.slice(1);
      if (word === 'As' || word === 'as') {
        word = 'AS';
      }
      words[i] = word;
    }
    return words.join(' ');
  }

  private setServiceInformationFileId(event: any) {
    this.customer.ServiceInformationFileId = event.detail.id;
  }

  private removeServiceInformationFileId(event: any) {
    this.customer.ServiceInformationFileId = null;
  }
}
